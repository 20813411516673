/**
 * 日期上午下午显示
 *
 * @param {String, Objec} date 日期字符串或日期对象
 * @returns String
 */
export function dateFormat(date) {
    if (!date) {
        return "";
    }
    const dateArr = date.split(' ')
    let dateStr = dateArr[0]
    if (dateArr[1]) {
        dateStr += dateArr[1].indexOf('12') === 0 ? " 下午" : " 上午";
    }
    return dateStr;
}


/**
 * 日期格式化
 * @param {Object, String} str 日期字符串
 * @returns String
 */
export function dateTimeFormat(str) {
    let dateStr;
    if (str instanceof Date) {
        dateStr = str.toJSON();
    } else {
        dateStr = new Date(str).toJSON();
    }
    return new Date(+new Date(dateStr) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
}