<template>
  <div>
    <div
      @click="fullScreenChangeFnBut"
      class="color_FF click-full-sceen font-size18 flex flex-pack-center"
      v-if="isClickFullscreen"
    >
      全屏<img src="@/assets/images/full-screen.png" alt="" />
    </div>
  </div>
</template>
<script>
import screenfull from "screenfull";

export default {
  data() {
    return {
      isClickFullscreen: true,
      isFullscreen: false,
      innerHeight: "",
    };
  },
  mounted() {
    // this.innerHeight = document.body.clientHeight;
    // window.onresize = () => {
    //   if (this.checkFull() == false) {
    //     this.isFullscreen = false;
    //     this.isClickFullscreen = true;
    //   }
    // };
    if (window.screen.height - window.document.body.offsetHeight > 5) {
      this.isFullscreen = false;
      this.isClickFullscreen = true;
    } else {
      this.isFullscreen = true;
      this.isClickFullscreen = false;
    }
    window.addEventListener("resize", this.fullScreenChangeFn);
  },
  methods: {
    // clickFullscreen() {
    //   this.isFullscreen = true;
    //   this.isClickFullscreen = false;
    //   screenfull.toggle();
    // },
    // checkFull() {
    //   var isFull =
    //     document.mozFullScreen ||
    //     document.webkitIsFullScreen ||
    //     document.webkitFullScreen ||
    //     document.msFullScreen;
    //   if (isFull === undefined) isFull = false;
    //   return isFull;
    // },

    fullScreenChangeFnBut() {
      this.isFullscreen = true;
      this.isClickFullscreen = false;
      screenfull.toggle();
    },
    //监听屏幕是否全屏
    fullScreenChangeFn() {
      if (window.screen.height - window.document.body.offsetHeight > 5) {
        this.isFullscreen = false;
        this.isClickFullscreen = true;
      } else {
        this.isFullscreen = true;
        this.isClickFullscreen = false;
      }
    },
  },
//   beforeDestroy() {
//     window.removeEventListener("resize", this.fullScreenChangeFn);
//   },
  // watch: {
  //   innerHeight: {
  //     handler(newVal, oldVal) {
  //       if (newVal != window.screen.height && oldVal == window.screen.height) {
  //         this.isFullscreen = false;
  //         this.isClickFullscreen = true;
  //       } else if (
  //         newVal == window.screen.height &&
  //         oldVal != window.screen.height
  //       ) {
  //         this.isFullscreen = true;
  //         this.isClickFullscreen = false;
  //       }
  //     },
  //   },
  //   deep: true,
  // },
};
</script>
